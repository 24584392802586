import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { LangContext } from 'browser/contexts/LangContext';

const InfoWrapper = styled.div`
  position: relative;
  padding: 20px 15px;
  background: #fff;
  width: 245px;
  box-shadow: 0px 6px 12px #00000014;
  border-radius: 4px;
  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
  }
`;

const Label = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 4px 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  font-size: 1.4rem;
  color: #5c5550;
  margin-top: 10px;
  white-space: nowrap;
  span {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Logo = styled.div`
  width: 120px;
  img {
    display: block;
    max-width: 160px;
    height: 60px;
  }
`;

const InvestmentTooltip = ({ flats, logo }) => {
  const { translate } = useContext(LangContext);

  return (
    <InfoWrapper>
      <Logo>
        <img src={logo} alt="logo" />
      </Logo>
      {flats && (
        <Label>
          <p>{translate('availableFlats')}:</p>
          <span>{flats}</span>
        </Label>
      )}
    </InfoWrapper>
  );
};

InvestmentTooltip.propTypes = {
  flats: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
};

export default InvestmentTooltip;
